<template>
  <div id="blog-detail">
    <div class="blogbackground" v-if="isNotShowErrorTip" v-show="allShow">
      <h1 class="title">{{ blogItem.title }}</h1>
      <p class="clear-fix">
        <el-button type="primary" size="small" class="modify" @click="modifyBlog"><i class="el-icon-edit"></i> 编辑文章
        </el-button>
      </p>
      <!-- 这里最开始是解析的本地的md文件，后面改为解析数据库返回的base编码过后的md格式的文本 -->
      <!-- <div class="markdown-body">
        <flex-marked></flex-marked>
      </div> -->
      <!-- 还是使用第三方省心吧 -->
      <!-- <div class="arrows" v-show="isShow" @click="scrollToTop">
        <svg viewBox="0 0 20 20" class="svg">
          <g stroke="none" stroke-width="1" fill="none">
            <polygon fill="#409EFF" points="10 0 20 20 0 20"></polygon>
          </g>
        </svg>
      </div> -->
      <div class="markdown-body" v-html="md"></div>
    </div>
    <el-empty description="哦豁暂时无内容" :image-size="400" v-else></el-empty>
    <el-backtop :bottom="100">
      <div style="{
        height: 100%;
        width: 100%;
        background-color: #f2f5f6;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
        color: #1989fa;
      }">
        UP
      </div>
    </el-backtop>
  </div>

</template>

<script>
  const marked = require("marked");
  import "highlight.js/styles/github.css";
  import hljs from "highlight.js";
  import "github-markdown-css";
  import {
    Base64
  } from "js-base64";
  import updateBlog from "network/updateBlog";
  import {
    CURRENT_BLOGID,
    CURRENT_INDEX
  } from "../store/mutation-types";

  import flexMarked from "assets/md/flex.md";
  export default {
    name: "blogDetail",
    data() {
      return {
        md: "",
        isShow: false,
        scrollTop: 0.0,
        animation: null,
        blogItem: {},
        allShow: false,
        lastParamId: '',
        isNotShowErrorTip: true
      };
    },
    // 加个监听吧，不然md的解析还是有点慢的，会造成上面有个空白的停留效果
    watch: {
      md(newValue, oldValue) {
        if (newValue) {
          this.allShow = true;
        }
      }
    },
    components: {
      flexMarked,
    },
    methods: {
      onScroll() {
        // 当前文档流的 scrollTop
        this.scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (this.scrollTop > 1000) {
          this.isShow = true;
        } else {
          this.isShow = false;
        }
      },
      scrollToTop() {
        const STEP = 220;
        this.scrollTop -= STEP;
        if (this.scrollTop < 0) {
          this.scrollTop = 0;
        }
        document.body.scrollTop = this.scrollTop;
        document.documentElement.scrollTop = this.scrollTop;
        if (this.scrollTop == 0) {
          window.cancelAnimationFrame(this.animation);
          return;
        }
        //  其实就是一个递归函数的调用，这里要注意在合适的地方终止动画
        this.animation = window.requestAnimationFrame(this.scrollToTop);
      },
      updateBlog(id) {
        updateBlog(id).then(res => {
          this.isNotShowErrorTip = true;
          this.blogItem = res;
          // 下面的操作保留吧，其实没什么作用，之前的思路不是很对
          this.$store.commit(CURRENT_BLOGID, this.blogItem.objectId);
          if (this.$store.state.blogIdArr) {
            if (this.$store.state.blogIdArr.length > 0) {
              var currentIndex = this.$store.state.blogIdArr.findIndex(obj => obj ==
                this.blogItem.objectId);
              this.$store.commit(CURRENT_INDEX, currentIndex);
            }
          }
          this.parseMd();
        }).catch(err => {
          this.isNotShowErrorTip = false;
        });
      },
      parseMd() {
        this.md = marked.parse(Base64.decode(this.blogItem.content));
      },
      modifyBlog() {
        // 路由跳转 这里得想办法把blog模型传过去
        this.$router.push({
          name: "WriteBlog",
          params: {
            type: "modifyBlog",
            objectId: this.blogItem.objectId
          }
        });
      }
    },
    created() {
      this.updateBlog(this.$route.params.id)
    },
    mounted() {
      // 设置markdown显示的样式
      var rendererMD = new marked.Renderer(); // 初始化
      marked.setOptions({
        //设置样式
        renderer: rendererMD,
        highlight: function (code) {
          return hljs.highlightAuto(code).value;
        },
        pedantic: false,
        gfm: true,
        tables: true,
        breaks: true,
        sanitize: false,
        smartLists: true,
        smartypants: false,
        xhtml: false
      });
      window.addEventListener("scroll", this.onScroll);
    },
    destroyed() {
      window.removeEventListener("scroll", this.onScroll);
    }
  };
</script>

<style scoped lang='less'>
  .title {
    /* px-to-viewport-ignore-next */
    font-size: 30PX;
    font-weight: 700;
    word-break: break-word;
    margin-bottom: 40px;
    text-align: left;
    line-height: 1.1em;
    margin-top: 10px;
  }

  .blogbackground {
    margin-left: 20%;
    margin-right: 20%;
    padding: 40px;
    line-height: 20px;
    background-color: #ffffff;
    border-radius: 10PX;
    height: 100%;
  }

  #blog-detail {
    padding-top: 50px;
    padding-bottom: 30px;
    background-color: #f8f8f8;
    min-height: 100vh;
  }

  .arrows {
    position: fixed;
    background-color: #ffffff;
    width: 50px;
    height: 50px;
    border-radius: 25px;

    bottom: 20%;
    right: 15%;
    // 这里就采用flex布局来居中吧
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
  }

  // 不加这个显示的不对 
  .svg {
    /* px-to-viewport-ignore-next */
    width: 20PX;
    /* px-to-viewport-ignore-next */
    height: 20PX;
    display: block;
  }

  .modify {
    float: right;
    margin-bottom: 30px;
    margin-right: 20px;
  }

  .modify:hover {
    color: black;
  }

  @media (max-width: 750px) {
    .blogbackground {
      padding: 10px 5px;
      margin-left: 10px;
      margin-right: 10px;
    }

    .arrows {
      right: 5%;
    }


  }
</style>